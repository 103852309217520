import tealium, { basePageObject } from '@tamman/tealium'

const TRACKING_CATEGORY = `Dep:Mkt:AFF`
const TRACKING_PAGENAME = `SG_Komen`

const tealiumConfig = {
  enableVTIMTracking: false,                /* default: false */
  enableScrollTracking: true,               /* default: true  */
  enableDeviceTracking: true,               /* default: true  */
  enableAppendAriaExpanded: false,          /* default: false */
  enableAppendAriaExpandedInversion: false, /* default: false */
  enableVideoControlsTracking: false,       /* default: false */
}

const ddoConfig = {
  disable_pageviewonload: false             /* default: false */
}

const tealiumBootCallback = (shouldBootTealium = true) => {
  if (!shouldBootTealium) {
    return false
  }
  tealium({
    ...tealiumConfig,
    ddo: {
      ...ddoConfig,
      page: {
        pageInfo: [
          {
            ...basePageObject,
            pageID: `${TRACKING_CATEGORY};${TRACKING_PAGENAME}`
          }
        ],
        category: {
          primaryCategory: TRACKING_CATEGORY
        },
        attributes: {
          gaId: [],
          standardDART: []
        }
      }
    }
  })
}

export { tealiumBootCallback }