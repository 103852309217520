export const BOA_LOGO = {
  href: 'https://www.bankofamerica.com/',
  id: 'bank-of-america-1',
  'aria-label': 'Bank of America Official partner of Susan G. Komen'
}

export const KOMEN_LOGO = {
  href: 'https://ww5.komen.org/',
  id: 'susan-g-komen-link',
  'aria-label': 'Susan G. Komen - opens a dialog'
}

export const PERSONAL_SCHEDULE_OF_FEES = {
  href: 'https://www.bankofamerica.com/deposits/resources/personal-schedule-fees.go',
  id: 'personal-schedule-fees-link'
}

export const APPLY_NOW = {
  href: 'https://bankofamerica.tt.omtrdc.net/m2/bankofamerica/ubox/page?mbox=komen&mboxDefault=https://secure.bankofamerica.com/applynow/timeout.go&at_property=1a8d0183-947a-8fa5-52e5-2b3fc472e468&tagname=Komen-DualProductLP',
  id: 'apply-now-pink-ribbon-creditcard'
}

export const OPEN_AN_ACCOUNT = {
  href: 'https://www.bankofamerica.com/deposits/resources/before-you-apply-2.go?CH_BAG=CHMA&AFFINITYGROUPID=62888',
  id: 'apply-now-pink-ribbon-banking'
}

export const FOOTNOTES_APPLY_NOW = {
  href: 'https://bankofamerica.tt.omtrdc.net/m2/bankofamerica/ubox/page?mbox=komen&mboxDefault=https://secure.bankofamerica.com/applynow/timeout.go&at_property=1a8d0183-947a-8fa5-52e5-2b3fc472e468&tagname=Komen-DualProductLP',
  id: 'footnotes-apply-now-pink-ribbon-creditcard'
}
