import { BANK_OF_AMERICA } from '@tamman/ui-utils'
import { Box } from '@tamman/ui-layout'
import { FootnoteLink } from '@tamman/ui-footnotes'
import CreditCardImage from '../images/CreditCard.png'
import BankingCardImage from '../images/BankingCard.png'
import { ExternalLink, Paragraph } from '@tamman/ui-core'
import { APPLY_NOW, OPEN_AN_ACCOUNT } from '../utils/links'
import { REG, MDASH, RSQ } from '../utils/characters'

const Cards = () => {
  return (
    <Box className='cards-wrapper' flex={{ md: true }} flexDirection={{ xs: 'column', md: 'row' }}>
      <Box as='section' className='card-col' flex flexDirection='column'>
        <img
          src={CreditCardImage}
          className='card-image'
          alt='pink ribbon credit card'
        />
        <h2>Pink Ribbon Credit Card</h2>
        <p>
          Every purchase you make benefits Susan G. Komen<sup>{REG}</sup>{' '}
          {MDASH} at no additional expense to you.
        </p>
        <Box ml='norm'>
          <p>
            <strong>Get a $200 online cash rewards</strong> bonus after you make
            at least $1,000 in purchases within the first 90 days of account
            opening&nbsp;&ndash; plus choose how you earn rewards.
            <FootnoteLink id='Footnote_link_1' href='#footnote-1'>
              1
            </FootnoteLink>
          </p>

          <p>
            <strong>Choose 3% cash back</strong> in the category of your choice.
          </p>

          <p>
            <strong>Automatic 2% cash back</strong> at grocery stores and
            wholesale&nbsp;clubs.
          </p>

          <p>
            <strong>Unlimited 1% cash back</strong> on all other purchases.
          </p>

          <p>
            Earn 3% and 2% cash back on the first $2,500 in combined purchases
            each quarter in the choice category, and at grocery stores and
            wholesale clubs, then earn unlimited 1% thereafter.
          </p>
          <p>
            Plus, if you open a qualifying account with this offer,{' '}
            <strong>{BANK_OF_AMERICA} will contribute an additional $200 to 
            Komen</strong>, increasing your impact in the fight against breast 
            cancer.
            <FootnoteLink id='Footnote_link_2' href='#footnote-2'>
              2
            </FootnoteLink>{' '}
          </p>
          <Paragraph mb='lg'>
            <strong>
              This online only offer may not be available elsewhere if you leave
              this page.
            </strong>
            &nbsp;You can take advantage of this offer when you apply now.
          </Paragraph>
        </Box>
        <ExternalLink className='btn' {...APPLY_NOW}>
          Apply now
        </ExternalLink>
      </Box>

      <Box as='section' className='card-col' flex flexDirection='column'>
        <img
          src={BankingCardImage}
          className='card-image'
          alt='pink ribbon banking card'
        />
        <h2>Pink Ribbon Banking</h2>
        <p>
          Open a qualifying Pink Ribbon checking account offered by{' '}
          {BANK_OF_AMERICA}
          <FootnoteLink id='Footnote_link_3' href='#footnote-3'>
            3
          </FootnoteLink>{' '}
          today and a contribution will be made to Susan G. Komen
          <sup>{REG}</sup>. You{RSQ}ll get statements and debit cards with the
          Susan G. Komen<sup>{REG}</sup> logo printed on them.
        </p>
        <Paragraph mb='lg'>
          Please note that only new Pink Ribbon Banking customers are eligible
          to help generate a {BANK_OF_AMERICA} contribution to Komen.
        </Paragraph>
        <ExternalLink className='btn' {...OPEN_AN_ACCOUNT}>
          Open an account
        </ExternalLink>
      </Box>
    </Box>
  )
}

export default Cards
