// eslint-disable-next-line
import React from 'react'

export const DIAMOND = (<>&#9670;</>)
export const REG = (<>&reg;</>)
export const AMP = (<>&amp;</>)
export const LDQ = (<>&ldquo;</>)
export const RDQ = (<>&rdquo;</>)
export const RSQ = (<>&rsquo;</>)
export const TRADE = (<>&trade;</>)
export const COPY = (<>&copy;</>)
export const ELLIP = (<>&hellip;</>)
export const MDASH = (<>&mdash;</>)
export const APOS = (<>&apos;</>)
