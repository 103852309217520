import React from 'react'
import { ExternalLink } from '@tamman/ui-core'
import { BOA_LOGO, KOMEN_LOGO } from '../utils/links'
import { Box } from '@tamman/ui-layout'
import BankLogoImage from '../images/BankOfAmericaLogo.svg'
import KomenLogoImage from '../images/KomenLogo.svg'
import LogoSeparatorImage from '../images/LogoSeparator.svg'
import LogoSubtextImage from '../images/LogoSubtext.svg'

import ExitModal from '@tamman/ui-exit-modal'
import { BANK_OF_AMERICA, BANK_OF_AMERICA_CAPS } from '@tamman/ui-utils'
import { ClassArrayToString } from '@tamman/ui-utils'

const Logo = ({ children, className = '', disableLinks = false, ...props }) => {
  const logoClasses = ClassArrayToString(['logo', className])
  const LogoControl = ({ disableLinks, className, hasInterstitial, children, ...props }) => {
    const modalProps = {
      className: 'susan-modal',
      flagscape: false,
      content: {
        heading: (
          <>YOU ARE NOW LEAVING {BANK_OF_AMERICA_CAPS}&apos;S SUSAN G. KOMEN<sup>&reg;</sup> PAGE</>
        ),
        body: (
          <>
            <p>
              By clicking Continue, you will be taken to a website that is not affiliated with { BANK_OF_AMERICA } and may offer a different privacy policy and level of security. { BANK_OF_AMERICA } is not responsible for and does not endorse, guarantee or monitor content, availability, viewpoints, products or services that are offered or expressed on other websites.
            </p>
            <p>
              To return to the previous page, simply click the Return to Previous Page button.
            </p>
          </>
        ),
        actionButton: <>Continue</>,
        cancelButton: <>Return to Previous Page</>
      }
    }

    return (
      <>
        { disableLinks &&
          <div className={className}>
            { children }
          </div>
        }

        {
          !disableLinks &&
          <>
            {
              hasInterstitial
                ? <ExitModal
                  hasModal
                  { ...props }
                  modalProps={modalProps}
                >
                  { children }
                </ExitModal>
                : 
                <ExternalLink className={className} { ...props }>
                  { children }
                </ExternalLink>
            }
          </>
        }
      </>
    )
  }

  return (
    <Box flex flexDirection='column' className={logoClasses} {...props}>
      <Box flex className='logo__row'>
        <LogoControl disableLinks={disableLinks} className='logo__bank-of-america' {...BOA_LOGO}>
          <img src={BankLogoImage} alt='bank of america logo' width='90' height='50' />
        </LogoControl>
        <img src={LogoSeparatorImage} alt='' width='2' height='50' className='logo__separator' />
        <LogoControl disableLinks={disableLinks} className='logo__komen' hasInterstitial { ...KOMEN_LOGO} closeText=''>
          <img src={KomenLogoImage} alt='susan g. komen logo' width='124' height='50' />
        </LogoControl>
      </Box>
      <img src={LogoSubtextImage} alt='official partner of susan g. komen' width='120' height='7' className='logo__subtext' />
    </Box>
  )
}

export default React.memo(Logo)
