import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { tealiumBootCallback } from './tealium'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
  tealiumBootCallback
);

