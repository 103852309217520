import './styles/index.scss'
import { Header, Footer, Main, Paragraph } from '@tamman/ui-core'
import Hero from './components/Hero'
import { FootnotesProvider } from '@tamman/ui-footnotes'
import SiteFootnotes from './components/Footnotes'
import Logo from './components/Logo'
import Cards from './components/Cards'
import GlobalFooter from '@tamman/ui-global-footer'
import { BANK_OF_AMERICA, TabCatcher } from '@tamman/ui-utils'
import { DialogPortal } from '@tamman/ui-modal'

const App = () => {
  return (
    <TabCatcher>
      <FootnotesProvider>
        <div className='App page-wrapper'>
          <Header>
            <Logo />
            <Hero />
          </Header>
          <Main className='site-main'>
            <Cards />
            <Paragraph my='lg'>
              {BANK_OF_AMERICA} will contribute an additional $200 (up to 
              $100,000.00 annually) to Komen for each new account that opens as part 
              of this campaign. Accounts must be used to make a transaction within, 
              and remain open for, the first 90 days of account opening.
            </Paragraph>
          </Main>
          <Footer>
            <div className='container'>
              <SiteFootnotes />
              <GlobalFooter
                legalID='MAP5870662'
                jobID='LP-09-23-0184'
                minDate={2022}
              />
            </div>
          </Footer>
        </div>
        <DialogPortal />
      </FootnotesProvider>
    </TabCatcher>
  )
}

export default App
