import { Heading, Paragraph } from "@tamman/ui-core"
import { Box } from "@tamman/ui-layout"
import { BANK_OF_AMERICA } from "@tamman/ui-utils"

const Hero = () => {
  return (
    <div className='hero row'>
      <div>
        <Box flex alignItems='center' className='titles'>
          <div>
            <Heading level='one'>
              Get <span className='pink'>Pink Ribbon</span> <br />
              Banking
            </Heading>
            <h2>
              Join the fight to help <br />
              end breast cancer
            </h2>
          </div>
        </Box>
        <Paragraph mb='none' className='content'>
          With our Pink Ribbon Banking credit card and
          checking account, {BANK_OF_AMERICA} will make contributions to help
          Susan G. Komen<sup>&reg;</sup> in the mission to end breast cancer.
          These contributions will help to save lives, provide quality care to 
          those in need, and support the essential science and research that 
          furthers the fight against breast cancer.
        </Paragraph>
      </div>
    </div>
  )
}

export default Hero
