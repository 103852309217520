import { Footnotes, FootnoteItem } from '@tamman/ui-footnotes'
import { BANK_OF_AMERICA, BANK_OF_AMERICA_CORPORATION } from '@tamman/ui-utils'
import { FOOTNOTES_APPLY_NOW, PERSONAL_SCHEDULE_OF_FEES } from '../utils/links'
import { ExternalLink } from '@tamman/ui-core'
import { REG } from '@tamman/ui-utils'

const SiteFootnotes = () => {
  return (
    <Footnotes>
      <FootnoteItem identifier='1' href='#Footnote_link_1'>
        For information about rates, fees, other costs and benefits associated
        with the use of this credit card, click on{' '}
        <ExternalLink {...FOOTNOTES_APPLY_NOW}>Apply now</ExternalLink> and
        refer to the disclosures accompanying the online credit application.
      </FootnoteItem>
      <FootnoteItem identifier='2' href='#Footnote_link_2'>
        <strong>Contribution Information.</strong> From January 1, 2021 through 
        December 31, 2026,{' '} {BANK_OF_AMERICA} guaranteed to Susan G. 
        Komen<sup>{REG}</sup> $3 million in conjunction with its Pink Ribbon 
        Banking program. Susan G. Komen<sup>{REG}</sup> has received more than 
        $12.2 million from{' '}{BANK_OF_AMERICA}{' '} from this program since 2009. 
        For each new Susan G. Komen Rewards credit card from {BANK_OF_AMERICA}{' '}
        that is used to make a transaction within, and remains open for, the 
        first 90 days of account opening, Komen receives at least $3 and 0.08% of 
        all purchases (less returns) made with the card. For example, 8 cents for 
        every $100. Komen also receives $3 for each renewal of a card that is open 
        with active charging privileges during the 12-month period and does not 
        have a zero balance at the time of the renewal.
      </FootnoteItem>
      <FootnoteItem identifier='3' href='#Footnote_link_3'>
        Pink Ribbon Banking is available with eligible checking accounts.
        <br />
        <br />
        The Running Ribbon is a registered trademark for Susan G. Komen
        <sup>{REG}</sup>.
        <br />
        <br />
        This credit card program is issued and administered by {BANK_OF_AMERICA}
        , N.A. Deposit products and services are provided by {BANK_OF_AMERICA},
        N.A., and affiliated banks, Members FDIC and wholly owned subsidiaries
        of {BANK_OF_AMERICA_CORPORATION}. Visa and Visa Signature are registered
        trademarks of Visa International Service Association and are used by the
        issuer pursuant to license from Visa U.S.A., Inc. {BANK_OF_AMERICA} and
        the {BANK_OF_AMERICA} logo are registered trademarks of{' '}
        {BANK_OF_AMERICA_CORPORATION}. The Contactless Symbol and Contactless
        Indicator are trademarks owned by and used with permission of EMVCo,
        LLC.
        <br />
        <br />
        Please review the{' '}
        <ExternalLink {...PERSONAL_SCHEDULE_OF_FEES}>
          Personal Schedule of Fees
        </ExternalLink>{' '}
        also available at your local financial center.
      </FootnoteItem>
    </Footnotes>
  )
}

export default SiteFootnotes
